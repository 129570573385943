import { t } from '@zupr/i18n'
import { ProductLocation } from '@zupr/types/fo'

interface Props {
    stock_prefs: ProductLocation['stock_prefs']
    stock_count: ProductLocation['stock_count']
    active?: ProductLocation['active']
}

export const label = ({ stock_prefs, stock_count, active }: Props): string => {
    if (active === false) return t('Discontinued')
    if (stock_prefs === 'in_stock') return t('In stock')
    if (stock_prefs === 'not_in_stock') return t('Not in stock')
    if (stock_prefs === 'call_for_stock') return t('Stock unknown', 'dutch')
    if (stock_prefs === 'exact_stock') {
        if (stock_count <= 0) return t('Not in stock')
        return t('%{stock_count} in stock', { stock_count })
    }
    return t('Stock unknown', 'dutch')
}
