import React, { useEffect, useRef, useState } from 'react'
import Tether from 'react-tether'

import '../../../scss/react/components/selector-menu.scss'
import '../../../scss/react/components/tether.scss'

import { useClickOutside } from '@zupr/hooks/ux'
import Sorter from '../../../svg/sorter.svg'

interface DropDownListProps {
    data: any[]
    width: number
    setWidth: (width: number) => void
    renderItem: (props: any) => React.ReactNode
    setOpen: (open: boolean) => void
}

const DropDownList = ({
    data,
    width,
    setWidth,
    renderItem,
    setOpen,
}: DropDownListProps) => {
    const list = useRef<HTMLUListElement>()
    useClickOutside(list, () => setOpen(false))

    // calculate the width of the menu items
    useEffect(() => {
        if (width) return
        const listWidth = Math.floor(list.current.getBoundingClientRect().width)
        if (listWidth > 620) return // site max width
        setWidth(listWidth)
    }, [setWidth, width])

    return (
        <ul
            ref={list}
            style={{
                opacity: width ? 1 : 0,
                width: width ? width - 2 : 'auto',
            }}
        >
            {data.map((item, index) => (
                <li key={`selector-item-${index}`}>
                    {renderItem({
                        ...item,
                        onClose: () => setOpen(false),
                    })}
                </li>
            ))}
        </ul>
    )
}

interface DropDownProps {
    attachment?: string
    targetAttachment?: string
    offset?: string
    data: any[]
    label: string
    renderItem: (props: any) => React.ReactNode
}

const Dropdown = ({
    attachment,
    targetAttachment,
    offset,
    label,
    ...props
}: DropDownProps) => {
    const holder = useRef<HTMLDivElement>()

    const [open, setOpen] = useState<boolean>()
    const [width, setWidth] = useState<number>()

    return (
        <Tether
            renderTarget={(ref) => (
                <div ref={holder} className="selector-menu">
                    <button
                        style={{ width: `${width - 2}px` }}
                        className="inline"
                        ref={ref}
                        onClick={() => {
                            setOpen(true)
                        }}
                    >
                        {label}
                        <Sorter />
                    </button>
                </div>
            )}
            renderElement={(ref) =>
                (open || !width) && (
                    <DropDownList
                        {...props}
                        width={width}
                        setWidth={(listWidth) => {
                            const holderWidth =
                                holder.current.getBoundingClientRect().width

                            // use the widest of either the holder or the list items
                            setWidth(Math.max(listWidth, holderWidth))
                        }}
                        setOpen={setOpen}
                    />
                )
            }
            // @ts-ignore
            attachment={attachment}
            targetAttachment={targetAttachment}
            className="selector-menu open"
            offset={offset}
            constraints={[
                {
                    to: 'window',
                    pin: true,
                },
            ]}
        />
    )
}

Dropdown.defaultProps = {
    attachment: 'top left',
    targetAttachment: 'bottom left',
    offset: '0px 1px',
}

export default Dropdown
