import { t } from '@zupr/i18n'
import { ProductLocation } from '@zupr/types/fo'
import { label as stockLabel } from '@zupr/utils/stock'

import { useShoppingListItem } from '../../../../frontoffice/features/shopping-list/hooks/item'

interface Props {
    stock_prefs: ProductLocation['stock_prefs']
    stock_count: ProductLocation['stock_count']
    active?: ProductLocation['active']
}

export const Label = ({ stock_prefs, stock_count, active }: Props) => {
    return <>{stockLabel({ stock_prefs, stock_count, active })}</>
}

export const InteractiveLabel = (productLocation) => {
    const { partOfInformationRequest, isRequesting, isAnswered, stockCount } =
        useShoppingListItem(productLocation)

    if (partOfInformationRequest && isRequesting) {
        return t('Wacht op voorraadstatus...')
    }

    if (partOfInformationRequest && isAnswered) {
        return (
            <Label
                {...productLocation}
                stock_prefs="exact_stock"
                stock_count={stockCount}
            />
        )
    }

    return <Label {...productLocation} />
}

const ProductLocationLabel = (productLocation) => {
    if (productLocation.location)
        return <InteractiveLabel {...productLocation} />
    return <Label {...productLocation} />
}

export default ProductLocationLabel
