import { t } from '@zupr/i18n'
import { Location } from '@zupr/types/fo'
import { Choice } from '@zupr/types/form'

export const methods = ['cash', 'pin', 'mastercard', 'visa', 'american-express'] // ,'ideal'

export const choices: Choice[] = methods.map((method) => ({
    value: method,
    display_name: t(method, 'payment_method'),
}))

export const formatPaymentMethods = (
    payment_methods: Location['payment_methods']
) =>
    Object.keys(payment_methods || {})
        .map((paymentMethod) => t(paymentMethod, 'payment'))
        .join(', ')
