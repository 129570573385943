import classnames from 'classnames'
import { useCallback, useEffect, useState } from 'react'

import currencies, {
    currencyToSymbol,
    sortedCurrencyCodes,
} from '@zupr/utils/currencies'

import { Field } from '@zupr/types/form'
import Select, { Option, SingleValue } from '../../components/select'
import { CombinedFields } from '../elements/row'

export const formatPrice = (value) =>
    (Number.isInteger(value) && (value / 100).toFixed(2)) || ''
const unformatPrice = (value) => Math.round(value * 100)
const countDecimals = (value) => {
    if (Math.floor(value) === Math.ceil(value)) return 0
    return `${value}`.replace(/,/g, '.').split('.')[1].length || 0
}

interface PriceFieldProps {
    field: Field
    className?: string
    small?: boolean
    onBlur?: () => void
}

export const PriceField = ({
    className,
    small,
    field,
    onBlur,
    ...props
}: PriceFieldProps) => {
    const [display, setDisplay] = useState(formatPrice(field.value)) // how to display price

    // update display when field value chages externaly
    useEffect(() => {
        if (!field.value) return
        if (unformatPrice(display) === field.value) return
        setDisplay(formatPrice(field.value))
    }, [display, field.value])

    const handlePrice = (value) => {
        // replace al illegal chars
        value = value.replace(/[^0-9.,]/g, '')
        // precent to many count decimals
        if (countDecimals(value) > 2) return
        setDisplay(value) // set display to latst input

        // value is null
        // reset currency to null too
        if (value === null || value === '') {
            field.setValue(null)
            return
        }

        // value contains comma
        // change it to point and start over
        if (value.includes(',')) {
            value = value.replace(/,/g, '.')
            return handlePrice(value)
        }

        // value is not a number
        // do not change the input
        if (isNaN(unformatPrice(value))) return

        // Only allow for one dot i price
        if (value.includes && value.includes('.')) {
            const split = value.split('.')
            if (split[1].length > 2) return
        }

        field.setValue(unformatPrice(value))
    }

    const handleBlur = useCallback(() => {
        if (!display) return
        setDisplay(formatPrice(unformatPrice(display)))
        if (onBlur) onBlur()
    }, [display, onBlur])

    const handleChange = ({ target }) => {
        const { value } = target
        return handlePrice(value)
    }

    return (
        <input
            type="text"
            className={classnames('input-price', { small }, className)}
            {...props}
            value={display}
            name={field.name}
            id={field.name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={field.placeholder}
            data-size={display.length}
        />
    )
}

interface PriceAndCurrencyProps {
    currency: Field
    price: Field
    small?: boolean
    className?: string
    defaultCurrency?: 'EUR' | 'USD'
    placeholder?: string
}

const PriceAndCurrency = ({
    currency,
    price,
    small,
    className,
    defaultCurrency,
    ...props
}: PriceAndCurrencyProps) => {
    const [displayCurrency, setDisplay] = useState(
        currency.value || defaultCurrency
    )

    const handleChangeCurreny = (option) => {
        setDisplay(option && option.value)
        if (price.value && option && option.value) {
            currency.setValue(option.value)
        }
    }

    // if price is emtied reset currency
    // if currency is empty but we have price fill it
    useEffect(() => {
        if (price.value === null) return currency.setValue(null)
        if (price.value && !currency.value)
            return currency.setValue(displayCurrency)
    }, [currency.value, price.value]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CombinedFields>
            <Select
                disabled={currency.read_only}
                isSmall={small}
                name={currency.name}
                value={displayCurrency}
                className={classnames(
                    'before-input',
                    'select-currency',
                    className
                )}
                onChange={handleChangeCurreny}
                isClearable={false}
                options={sortedCurrencyCodes.map((code) => ({
                    value: currencies[code].Code,
                    label: currencies[code].Symbol,
                }))}
                components={{
                    Option: ({ children, data, ...props }) => (
                        <Option data={data} {...props}>
                            {children} <small>{data.value.toLowerCase()}</small>
                        </Option>
                    ),
                    SingleValue: ({ children, data, ...props }) => (
                        <SingleValue data={data} {...props}>
                            {children}{' '}
                            {!small && (
                                <small>{data.value.toLowerCase()}</small>
                            )}
                        </SingleValue>
                    ),
                }}
            />
            <PriceField
                {...props}
                field={price}
                small={small}
                className={classnames(className, 'after-input')}
            />
        </CombinedFields>
    )
}

PriceAndCurrency.defaultProps = {
    defaultCurrency: 'EUR',
}

interface PriceOnlyProps extends PriceFieldProps {
    currency: 'EUR'
}

export const PriceOnly = ({
    field,
    currency,
    className,
    small,
    ...props
}: PriceOnlyProps) => (
    <CombinedFields>
        <span className={classnames('before-input', { small })}>
            {currencyToSymbol(currency)}
        </span>

        <PriceField
            {...props}
            small={small}
            field={field}
            className={classnames('after-input', className)}
        />
    </CombinedFields>
)

PriceOnly.defaultProps = {
    currency: 'EUR',
}

export default PriceAndCurrency
