import Link from 'next/link'
import React, { useCallback, useMemo } from 'react'
import { UrlObject } from 'url'

import { ProductLocation } from '@zupr/types/fo'
import { List } from '@zupr/types/generic'
import { Product as SharedProduct } from '@zupr/types/shared'

import isEqual from 'lodash/isEqual'

import Trans from '../../components/trans'

import SelectorMenu from '../../components/selector-menu'

import '../../../../scss/react/product/variations.scss'

type CreateVariationRoute = (props: {
    id: string
    field?: string
    variationFields?: Product['variation_fields']
}) => string | UrlObject

type Product = Omit<
    SharedProduct,
    | 'images'
    | 'product_additional_data'
    | 'categories'
    | 'brand'
    | 'product_type'
>

interface VariationRouteProps {
    onVariationRoute: CreateVariationRoute
    onVariation?: never
}
interface VariationCallbackProps {
    onVariation: ({ id, field, variationFields }) => void
    onVariationRoute?: never
}

type VariationLinkProps = VariationRouteProps | VariationCallbackProps

type MenuItemProps = VariationLinkProps & {
    variationFields: Product['variation_fields']
    id: string
    field: string
    availableIds: Product['id'][]
    onClick: () => void
}

const MenuItem = ({
    variationFields,
    id,
    field,
    onVariation,
    onVariationRoute,
    availableIds,
    onClick,
}: MenuItemProps) => {
    const route = useMemo(() => {
        if (!onVariationRoute) return null
        return onVariationRoute({
            id,
            field,
            variationFields,
        })
    }, [id, field, variationFields, onVariationRoute])

    const handleVariation = useCallback(() => {
        onClick()
        onVariation({
            id,
            field,
            variationFields,
        })
    }, [field, id, onClick, onVariation, variationFields])

    return (
        <React.Fragment>
            {onVariationRoute && availableIds.includes(id) && (
                <Link href={route}>
                    <a onClick={onClick}>{variationFields[field] || id}</a>
                </Link>
            )}
            {onVariation && availableIds.includes(id) && (
                <button
                    className="inline"
                    type="button"
                    onClick={handleVariation}
                >
                    <span>{variationFields[field] || id}</span>
                </button>
            )}
            {!availableIds.includes(id) && (
                <span>{variationFields[field] || id}</span>
            )}
        </React.Fragment>
    )
}

type MenuProps = VariationLinkProps & {
    value: string
    variations: Product[]
    field: string
    availableIds: Product['id'][]
}

const Menu = ({ value, variations, ...props }: MenuProps) => {
    if (variations.length <= 1) {
        return <div className="no-variations">{value}</div>
    }

    return (
        <SelectorMenu
            label={value || '-'}
            data={variations}
            renderItem={({ variationFields, id, onClose }) => (
                <MenuItem
                    {...props}
                    variationFields={variationFields}
                    id={id}
                    onClick={onClose}
                />
            )}
        />
    )
}

Menu.defaultProps = {
    availableIds: [],
}

type ProductVariationsProps = VariationLinkProps & {
    variationFields: Product['variation_fields']
    variationForField: (string) => Product[]
    noLabels?: boolean
    availableIds: Product['id'][]
}

const ProductVariations = ({
    variationFields,
    variationForField,
    noLabels,
    ...props
}: ProductVariationsProps) => (
    <div className="product-variations">
        {Object.keys(variationFields)
            .sort()
            .map((field) => {
                const value = variationFields[field]
                const variations = variationForField(field)
                if (variations.length === 1 && !value) return null

                const fieldLabel = !field.includes('.')
                    ? field
                    : field.split('.').pop()

                return (
                    <div key={field} className="product-variations-field">
                        {!noLabels && (
                            <label htmlFor={field} key={`variation.${field}`}>
                                <Trans label={fieldLabel} context="fields" />
                                {`:`}
                            </label>
                        )}
                        <Menu
                            {...props}
                            value={variationFields[field]}
                            field={field}
                            variations={variations}
                        />
                    </div>
                )
            })}
    </div>
)

type ProductVariationsLoaderProps = VariationLinkProps & {
    variations: List<Product>
    availableVariations?: List<ProductLocation>
    variationFields: Product['variation_fields']
    noLabels?: boolean
}

const ProductVariationsLoader = ({
    variations,
    availableVariations,
    variationFields,
    ...props
}: ProductVariationsLoaderProps) => {
    const availableIds =
        (availableVariations?.count &&
            availableVariations.results?.map(({ product }) => product.id)) ||
        variations.results.map(({ id }) => id)

    const variationForField = (field) => {
        const variationsOfField = []

        // make copy of this product variations fields
        const variationFieldsToCompare = { ...variationFields }

        // and delete the field
        delete variationFieldsToCompare[field]

        // compare it with all variations
        variations?.results.forEach((variation) => {
            // get a copy of variation fields for product
            const productVariationFieldsToCompare = {
                ...variation.variation_fields,
            }
            delete productVariationFieldsToCompare[field]

            if (
                isEqual(
                    productVariationFieldsToCompare,
                    variationFieldsToCompare
                )
            ) {
                variationsOfField.push({
                    id: variation.id,
                    variationFields: variation.variation_fields,
                })
            }
        })

        const sizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', '3XL']

        // if all variations are of type "size"
        if (
            variationsOfField.every(
                (item) =>
                    item.variationFields[field] &&
                    sizes.includes(
                        `${item.variationFields[field]}`.toUpperCase()
                    )
            )
        ) {
            return variationsOfField.sort((a, b) =>
                sizes.indexOf(a.variationFields[field].toUpperCase()) >
                sizes.indexOf(b.variationFields[field].toUpperCase())
                    ? 1
                    : -1
            )
        }

        // normal sorting
        return variationsOfField.sort((a, b) =>
            a.variationFields[field] >= b.variationFields[field] ? 1 : -1
        )
    }

    return (
        <ProductVariations
            {...props}
            variationFields={variationFields}
            variationForField={variationForField}
            availableIds={availableIds}
        />
    )
}

type VariationsProps = VariationLinkProps & {
    product: Product
    variations: List<Product>
    availableVariations?: List<ProductLocation>
    noLabels?: boolean
}

const Variations = ({ product, variations, ...props }: VariationsProps) => {
    if (!product) return null
    if (!variations) return null

    // get the variations for this product
    const variationFields = product.variation_fields

    // stop if there are no variations
    if (!Object.keys(variationFields).length) return null

    return (
        <ProductVariationsLoader
            variations={variations}
            variationFields={variationFields}
            {...props}
        />
    )
}

export default Variations
