import { useItem } from '@zupr/hooks/request-redux'
import { useMemo } from 'react'

import useBook from './book'
import useClothing from './clothing/info'
import useFood from './food/info'
import useNutrients from './food/nutrients'

import ProductData, { useCodes } from './data'

import useColorsAndMaterials from './clothing/colors-and-materials'
import useWashAndCare from './clothing/wash-and-care'

import useTargetCountries from '../target-countries'

import '../../../../scss/react/product/data.scss'

// get pad from bo
const useBoPad = ({ product }) => {
    const { _related } = product

    const padKey = useMemo(() => {
        if (!_related) return
        return (
            _related.pad_templates &&
            Object.keys(_related.pad_templates).find(
                (key) => !!_related.pad_templates[key]
            )
        )
    }, [_related])

    const padUrl = useMemo(() => {
        if (!_related) return
        return _related.pad_templates && _related.pad_templates[padKey]
    }, [_related, padKey])

    const [pad] = useItem({
        pause: !padUrl,
        url: padUrl,
    })

    return (pad && { [padKey]: pad }) || {}
}

const AdditionalData = ({ product }) => {
    const { product_additional_data } = product
    const boPad = useBoPad({ product })

    const {
        book,
        other,
        clothing,
        target_countries,
        footwear,
        jewellery,
        food,
        alcoholic_beverage,
    } = product_additional_data || boPad || {}

    const colorsAndMaterials = useColorsAndMaterials({
        type: !!footwear ? 'footwear' : 'clothing',
        ...(clothing || footwear || {}),
    })

    const clothingSpecs = useClothing({
        ...(clothing || footwear || {}),
    })

    const washAndCareSpecs = useWashAndCare({
        ...(clothing || footwear || {}),
    })

    const targetCountries = useTargetCountries({
        target_countries,
    })

    const otherSpecs = []
    const bookSpecs = useBook({ book })

    if (jewellery && jewellery.material) {
        otherSpecs.push([
            {
                label: 'Material',
                value: jewellery.material,
            },
        ])
    }

    const foodSpecs = useFood({ food: food || alcoholic_beverage })
    const nutrientSpecs = useNutrients({
        ...(food || alcoholic_beverage || {}),
    })

    const codes = useCodes(product)

    const generalGroup = {
        title: 'General',
        specs: [
            ...otherSpecs,
            ...bookSpecs,
            ...colorsAndMaterials,
            ...foodSpecs,
            ...nutrientSpecs,
            ...targetCountries,
            ...clothingSpecs,
        ],
    }

    const otherGroups = [...((other && other.specs) || [])].map(
        ({ name, items }) => ({
            title: name,
            specs: items.map(({ name, value }) => ({
                label: name,
                value,
            })),
        })
    )

    const codesGroup = {
        title: 'Codes',
        specs: codes,
    }

    const groups = [generalGroup]

    if (washAndCareSpecs.length) {
        groups.push({
            title: 'Wash instructions',
            specs: washAndCareSpecs,
        })
    }

    return <ProductData groups={[...groups, ...otherGroups, codesGroup]} />
}

export default AdditionalData
