import { useDiscountPrice } from '@zupr/utils/price'

import Price from '../product/price'

// like product price but with discount
const ProductLocationPrice = ({
    price,
    original_price,
    currency,
    retail_price,
    discount_price,
    discount_period_start,
    discount_period_end,
    is_discount_price,
}) => {
    const discountPrice = useDiscountPrice({
        price,
        is_discount_price,
        discount_price,
        discount_period_start,
        discount_period_end,
    })

    if (!!discountPrice) {
        return (
            <>
                <Price
                    price={original_price || retail_price || price}
                    currency={currency}
                    striked
                />
                <Price price={discountPrice} currency={currency} discounted />
            </>
        )
    }
    return <Price price={retail_price || price} currency={currency} />
}

export default ProductLocationPrice
